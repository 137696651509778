import React from 'react'
import styled from 'styled-components';
import Icon1 from '../../assets/stat1.inline.svg';
import Icon2 from '../../assets/stat2.inline.svg';
import Icon3 from '../../assets/stat3.inline.svg';
import Icon4 from '../../assets/stat4.inline.svg';
import Icon5 from '../../assets/stat5.inline.svg';
import Stat from '../global/Stat';

const AboutDemographicsWrap = styled.section`
padding:3rem 0 4rem 0;

    @media only screen and (min-width: 750px) {
    padding:5rem 0 4rem 0;
    }

    @media only screen and (min-width: 1024px) {
    padding:8rem 0 4rem 0;
    }

    .heading--l{
    margin-bottom:2rem;
    }

    .text{
    max-width:560px;
    line-height:1.5rem;
    margin-bottom:4rem;
    }

    ul{
    list-style:none;
    padding:0;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    
        li{
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start;
        align-content: flex-start;
        width:50%;

            @media only screen and (min-width: 750px) {
                width:33.333%;
            }

            @media only screen and (min-width: 1024px) {
                width:20%;
            }


            .top{
            display:flex;
            align-items:center;   
            margin-bottom:0.8rem;
            }

            .icon{
            margin-right:1rem;
            }

            .stat{
            color:var(--red);
            font-family:var(--font-heading);
            font-size:4rem;
            }

            .text{
            width:100%;
            display:flex;
            align-items:flex-start;

                .text--inner{
                max-width:200px;
                line-height:1.5rem;
                font-size:0.9rem;
                }

            }
        }

    }
`

export default function AboutDemographics({stats}) {
    return (
        <AboutDemographicsWrap>
            <div className="wrapper">
                <h2 className="heading--l">Demographics and reach stats</h2>
                <div className="text">59% Female / 41% Male audience | Average age 25 to 37 years | Interested in Entertainment and Culture, Food and Drink, Shopping and Lifestyle</div>
                <ul>
                    {
                        stats.options.acf_options.stats.map((stat, index) => {
                            return(
                                <li key={index +'_'+stat.icon }>
                                    <div className="top">
                                        {
                                            stat.icon === 'book' ? 
                                                <Icon1 className="icon"/>
                                            : stat.icon === 'magazine' ?
                                                <Icon2 className="icon"/>
                                            : stat.icon === 'followers' ?
                                                <Icon3 className="icon"/>
                                            : stat.icon === 'engagement' ?
                                                <Icon4 className="icon"/>
                                            : stat.icon === 'siteVisits' ?
                                                <Icon5 className="icon"/>
                                            : ""
                                        }
                                        
                                        <Stat 
                                        start={0}
                                        end={stat.stat}
                                        prefix={''}
                                        suffix={stat.statSuffix}
                                        />
                                    </div>
                                    <div className="text">
                                        <div className="text--inner">{stat.longLabel}</div>
                                    </div>
                                    
                                </li>
                            )
                        })
                    }
                    
                </ul>
            </div>
        </AboutDemographicsWrap>
    )
}
