import React, { useState, useRef } from "react"
import styled from "styled-components"
import FakeButtonModal from "../global/FakeButtonModal"
import PromotionsTableRow from "./PromotionsTableRow"
import PromotionsPickMixRow from "./PromotionsPickMixRow"
import { IoChevronDownSharp } from "react-icons/io5"
import DD from "../../assets/dd.inline.svg"

const PromotionsPackagesComp = styled.section`
  position: relative;
  background: var(--teal);
  color: var(--white);
  padding: 4rem 0 0 0;
  border-top: 1px solid #676767;
  border-bottom: 1px solid #676767;

  @media only screen and (min-width: 1024px) {
    padding: 1rem 0 4rem 0;
    border-top: 0;
    border-bottom: 0;
  }

  .heading--l {
    /* color:var(--brandColor); */
    color: var(--white);
    margin-bottom: 2rem;

    @media only screen and (min-width: 1024px) {
      margin-bottom: 4rem;
    }
  }

  .disclaimer {
    width: 100%;
    margin-bottom: 2rem;
  }

  .mob-only {
    @media only screen and (min-width: 1024px) {
      display: none !important;
    }
  }

  .table {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    padding: 2rem 2rem 0 2rem;

    @media only screen and (min-width: 1024px) {
      padding: 0 2rem;
      border: 1px solid #676767;
    }

    .row {
      min-width: 100%;
      display: flex;
      /* padding-bottom:2rem; */
      transition: 0.5s all ease-in-out;
      flex-wrap: wrap;
      justify-content: space-around;

      @media only screen and (min-width: 1024px) {
        flex-wrap: nowrap;
        justify-content: inherit;
      }

      &.showButton {
        /* margin-bottom:2rem; */
      }

      &.picknmix-row,
      &.row-detail {
        display: none;

        @media only screen and (min-width: 1024px) {
          display: flex;
        }
      }

      .cell {
        width: 100%;
        margin-bottom: 4rem;
        transition: 0.5s all ease-in-out;

        &.bronze,
        &.silver,
        &.gold,
        &.platinum {
          @media only screen and (min-width: 1024px) {
            cursor: pointer;
          }
        }

        &.hover {
          @media only screen and (min-width: 1024px) {
            background: var(--white);
            color: var(--black);
          }
        }

        /* @media only screen and (min-width: 500px) {
                width:50%;
                } */

        @media only screen and (min-width: 1024px) {
          width: calc(100% / 6);
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0 !important;
          border-bottom: 0 !important;

          @media only screen and (min-width: 1024px) {
            margin-bottom: 4rem !important;
            /* border-bottom:1px solid rgba(0,0,0,.3) !important; */
          }
        }

        &.empty {
          display: none;

          @media only screen and (min-width: 1024px) {
            display: inherit;
          }
        }

        &.package {
          text-align: left;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          position: relative;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          transition: 0.5s all ease-in-out;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);

          @media only screen and (min-width: 1024px) {
            text-align: center;
            border-bottom: 0;
            margin-bottom: 0;
            padding: 4rem 0;
          }

          &.showButton {
            padding-bottom: 4rem;

            @media only screen and (min-width: 1024px) {
              padding-bottom: 0;
            }
          }

          &.bespoke {
            justify-content: space-between;
          }

          .package-title {
            margin-bottom: 0.5rem;
            width: 100%;
          }

          .package-name {
            margin-bottom: 1.5rem;
            font-size: 0.8rem;
            color: var(--black);
            width: 100%;
          }

          .price-per-month {
            font-family: var(--font-bold);
          }

          .price-per-annum,
          .price-per-month {
            width: 100%;
            font-size: 1.3rem;

            span {
              font-size: 0.8rem;
            }
          }

          .divider {
            width: 100%;
            position: relative;
            padding: 0.5rem 0;

            &:before {
              width: 100px;
              margin: 0;
              opacity: 0.5;
              height: 1px;
              display: block;
              background: var(--white);
              content: "";
              position: relative;

              @media only screen and (min-width: 1024px) {
                margin: 0 auto;
              }
            }
          }

          .saving {
            width: 100%;
            font-size: 0.6rem;
            color: var(--black);
            padding-top: 0.5rem;
          }

          .mob-info {
            display: block;

            @media only screen and (min-width: 1024px) {
              display: none;
            }

            .label {
              font-family: var(--font-bold);
              margin-bottom: 1rem;
              position: relative;
              display: block;
              color: var(--white);
            }
            ul {
              list-style: none;
              padding: 0;

              li {
                margin-bottom: 1rem;
                width: 100%;
                line-height: 1.5rem;
                display: flex;
                flex-wrap: wrap;

                span {
                  color: var(--black);
                  width: 100%;
                  position: relative;
                  display: block;
                  margin-bottom: 0.5rem;
                }
              }
            }
          }

          .button_selector {
            position: relative;
            background-color: var(--brandColor);
            border: 0;
            font-size: 0.8rem;
            transition: 0.5s all ease-in-out;
            cursor: pointer;
            /* color:var(${process.env.GATSBY_SITENAME === "York"
              ? "--white"
              : "--black"});
                    color:purple; */
            /* color:var(--black); */

            @media only screen and (min-width: 1024px) {
              margin: 0 auto;
            }

            select {
              background: none;
              border: 0;
              padding: 0;
              transition: 0.5s all ease-in-out;
              width: 100%;
              color: var(
                ${process.env.GATSBY_SITENAME === "York"
                  ? "--white"
                  : "--black"}
              );
              padding: 0.5rem 40px 0.5rem 1rem;
            }

            &:hover {
              background: var(--black);
              color: var(--white);

              select {
                color: var(--white);
              }
            }

            .dd {
              position: absolute;
              width: 10px;
              height: 100%;
              right: 0.5rem;
              bottom: 0px;
              pointer-events: none;

              polygon {
                fill: var(--black);
                fill: var(
                  ${process.env.GATSBY_SITENAME === "York"
                    ? "--white"
                    : "--black"}
                );
                transition: 0.5s all ease-in-out;
              }
            }

            &:hover {
              .dd {
                polygon {
                  fill: var(--brandColor);
                }
              }
            }
          }

          .button-wrap {
            width: 100%;

            &.monthly,
            &.annual {
              margin-top: 0.5rem;
              position: absolute;
              left: 0;
              bottom: 4rem;
              transform: translateY(calc(100% + 0.5rem));
              transition: 0.5s all ease-in-out;
              /* 
                            @media only screen and (min-width: 1024px) {
                            bottom:0;
                            } */
            }

            &.hide {
              opacity: 0;
              transform: translateY(calc(0));
              pointer-events: none;
            }

            .fake_button {
              width: 100%;
            }

            .fake_button .button,
            .button,
            button {
              background: var(--brandColor);
              border: 0;
              padding: 0.5rem 1rem;
              font-size: 0.8rem;
              transition: 0.5s all ease-in-out;
              cursor: pointer;
              color: var(
                ${process.env.GATSBY_SITENAME === "York"
                  ? "--white"
                  : "--black"}
              );
              /* color:var(--black); */
              width: calc(100% - 1rem);
              margin: 0 auto;
              width: 100%;

              @media only screen and (min-width: 1024px) {
                max-width: 127px;
              }

              &:hover {
                background: var(--black);
                color: var(--white);
              }
            }
          }

          .price {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .div-row {
      background: rgba(255, 255, 255, 0.2);
      width: calc(100% + 4rem);
      margin-left: -2rem;
      padding: 0.75rem 2rem;
      font-size: 0.8rem;
      color: var(--black);
      text-align: center;
      display: none;

      @media only screen and (min-width: 1024px) {
        display: block;
      }
    }

    .picknmix-row {
      background: #92a3a3;
      width: calc(100% + 4rem);
      margin-left: -2rem;
      padding: 4rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: 0.5s all ease-in-out;
      border: 0;
      color: var(--white);

      &:focus {
        outline: none;
      }

      &:hover {
        background: var(--white);
        color: var(--black);
      }

      .heading--m {
        margin-bottom: 1rem;
      }

      .poa {
        color: var(--black);
        font-family: var(--font-bold);
      }

      .icon {
        text-align: center;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`

export default function PromotionsPackages(data) {
  const [pickMixToggle, setPickMixToggle] = useState(false)
  const packagesTable = useRef()
  const handleClick = () => {
    if (pickMixToggle === false) {
      setPickMixToggle(true)
    } else {
      setPickMixToggle(false)
    }
  }
  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      if (pickMixToggle === false) {
        setPickMixToggle(true)
      } else {
        setPickMixToggle(false)
      }
    }
  }
  const handleChange = e => {
    const siblings = Array.from(e.target.parentNode.parentNode.childNodes)
    const parent = e.target.parentNode.parentNode.parentNode
    const monthly = siblings.filter(sibling =>
      sibling.classList.contains("monthly")
    )
    const annual = siblings.filter(sibling =>
      sibling.classList.contains("annual")
    )

    parent.classList.add("showButton")
    e.target.parentNode.parentNode.classList.add("showButton")

    const isClient =
      typeof window !== "undefined" || typeof document !== "undefined"

    if (isClient) {
      const buttons = Array.from(
        document.querySelectorAll(".button-wrap.monthly, .button-wrap.annual")
      )

      buttons.map(button => button.classList.add("hide"))

      if (e.target.value === "Pay monthly") {
        monthly.map(month => month.classList.remove("hide"))
      }
      if (e.target.value === "Pay annually") {
        annual.map(year => year.classList.remove("hide"))
      }
    }
  }

  const packageHighlighterRemove = e => {
    const allCells = Array.from(packagesTable.current.querySelectorAll(".cell"))
    allCells.map(item => {
      return item.classList.remove("hover")
    })
  }

  const packageHighlighter = e => {
    const bronze = Array.from(packagesTable.current.querySelectorAll(".bronze"))
    const silver = Array.from(packagesTable.current.querySelectorAll(".silver"))
    const gold = Array.from(packagesTable.current.querySelectorAll(".gold"))
    const member = Array.from(
      packagesTable.current.querySelectorAll(".yorkshire-local-member")
    )

    if (e.target.closest(".yorkshire-local-member")) {
      member.map(item => {
        return item.classList.add("hover")
      })
    }
    if (e.target.closest(".bronze")) {
      bronze.map(item => {
        return item.classList.add("hover")
      })
    }
    if (e.target.closest(".silver")) {
      silver.map(item => {
        return item.classList.add("hover")
      })
    }
    if (e.target.closest(".gold")) {
      gold.map(item => {
        return item.classList.add("hover")
      })
    }
  }

  return (
    <PromotionsPackagesComp>
      <div className="wrapper">
        <h3 className="heading--l">Promotions Packages</h3>
        <div className="disclaimer mob-only">
          All prices are + vat. All our marketing packages are contracted to run
          for a 12 month period.
        </div>
        <div className="disclaimer mob-only">
          <strong>
            Non-independents, please get in touch if you'd like to work with us.{" "}
          </strong>
        </div>
        <div
          className="table"
          ref={packagesTable}
          onMouseOver={packageHighlighter}
          onFocus={packageHighlighter}
          onMouseOut={packageHighlighterRemove}
          onBlur={packageHighlighterRemove}
        >
          <div className="row row-packages">
            <div className="cell empty"></div>

            <div className="cell package yorkshire-local-member">
              <div className="name">
                <div className="package-title heading--m">
                  Yorkshire
                  <br /> Local Member
                </div>
              </div>
              <div className="price">
                <div className="price-per-month">£9.99</div>
                <div className="divider"></div>
                <div className="price-per-annum">
                  <span>annual fee</span>
                </div>
              </div>
              <div className="mob-info">
                <span className="label">Whats included: </span>
                <ul>
                  <li>
                    <span>Website:</span> Online Profile (with google maps link
                    and offers function)
                  </li>
                  <li>
                    <span>Little Black Book:</span> 20% discount on Little Black
                    Book Business Profile
                  </li>
                  <li>
                    <span>Social Media:</span> Dedicated Instagram Story &
                    Inclusion in a Social Post
                  </li>
                  <li>
                    <span>Other:</span> Window Sticker
                  </li>
                </ul>
              </div>
              <div className="button_selector">
                <select
                  onChange={handleChange}
                  name="yorkshire-local-member-select"
                  id="yorkshire-local-member-select"
                >
                  <option selected disabled>
                    Pick a plan
                  </option>
                  <option>Pay annually</option>
                </select>
                <DD className="dd" />
              </div>
              <div className="button-wrap annual hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="yorkshire-local-member"
                  data-item-name="Yorkshire Local: Annual"
                  data-item-price="9.99"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Yorkshire Local: Annual"
                  data-item-selected-plan="yorkshire-local-member"
                  data-plan1-id="yorkshire-local-member"
                  data-plan1-name="Yorkshire Local: Annual"
                  data-plan1-frequency="Yearly"
                  data-plan1-interval="1"
                  data-item-plan1-price="9.99"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
            </div>
            <div className="cell package bronze">
              <div className="name">
                <div className="package-title heading--m">
                  Bronze
                  <br /> Member
                </div>
              </div>
              <div className="price">
                <div className="price-per-month">
                  £40 <span>/ month</span>
                </div>
                <div className="divider"></div>
                <div className="price-per-annum">
                  £480 <span>/ year</span>
                </div>
              </div>
              <div className="mob-info">
                <span className="label">Whats included: </span>
                <ul>
                  <li>
                    <span>Website:</span> Premium Online Profile (includes
                    extended business description, additional photos and
                    clickable links to website and socials) & Inclusion in Blog
                    Content
                  </li>
                  <li>
                    <span>Little Black Book:</span> 20% discount on Little Black
                    Book Business Profile
                  </li>
                  <li>
                    <span>Social Media:</span> Dedicated Instagram Story & 2 x
                    Inclusion in a Social Post
                  </li>
                  {/* <li>
                    <span>Email Marketing:</span> Inclusion in a newsletter
                  </li> */}
                  <li>
                    <span>Other:</span> Window Sticker
                  </li>
                </ul>
              </div>
              <div className="button_selector">
                <select
                  onChange={handleChange}
                  name="bronze-select"
                  id="bronze-select"
                >
                  <option selected disabled>
                    Pick a plan
                  </option>
                  <option>Pay monthly</option>
                  <option>Pay annually</option>
                </select>
                <DD className="dd" />
              </div>
              <div className="button-wrap monthly hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="bronze-monthly"
                  data-item-name="Bronze Member: Monthly"
                  data-item-price="40"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Bronze Member: Monthly"
                  data-item-selected-plan="bronze-monthly"
                  data-plan1-id="bronze-monthly"
                  data-plan1-name="Bronze Member: Monthly"
                  data-plan1-frequency="Monthly"
                  data-plan1-interval="1"
                  data-item-plan1-price="40"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
              <div className="button-wrap annual hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="bronze-annually"
                  data-item-name="Bronze Member: Annually"
                  data-item-price="480"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Bronze Member: Annually"
                  data-item-selected-plan="bronze-annually"
                  data-plan1-id="bronze-annually"
                  data-plan1-name="Bronze Member: Annually"
                  data-plan1-frequency="Yearly"
                  data-plan1-interval="1"
                  data-item-plan1-price="480"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
            </div>
            <div className="cell package silver">
              <div className="name">
                <div className="package-title heading--m">
                  Silver
                  <br /> Member
                </div>
              </div>
              <div className="price">
                <div className="price-per-month">
                  £99 <span>/ month</span>
                </div>
                <div className="divider"></div>
                <div className="price-per-annum">
                  £1,188 <span>/ year</span>
                </div>
              </div>
              <div className="mob-info">
                <span className="label">Whats included: </span>
                <ul>
                  <li>
                    <span>Website:</span> Premium Online Profile (includes
                    extended business description, additional photos and
                    clickable links to website and socials) & Inclusion in Blog
                    Content & Dedicated Blogpost & Story Advert (3 months)
                  </li>
                  <li>
                    <span>Little Black Book:</span> Little Black Book Business
                    Profile
                  </li>
                  <li>
                    <span>Social Media:</span> Dedicated Instagram Story & 2 x
                    Inclusion in a Social Post & Dedicated Social Post
                  </li>
                  {/* <li>
                    <span>Email Marketing:</span> 2 x Inclusion in a newsletter
                  </li> */}
                  <li>
                    <span>Other:</span> Window Sticker
                  </li>
                </ul>
              </div>
              <div className="button_selector">
                <select
                  onChange={handleChange}
                  name="silver-select"
                  id="silver-select"
                >
                  <option selected disabled>
                    Pick a plan
                  </option>
                  <option>Pay monthly</option>
                  <option>Pay annually</option>
                </select>
                <DD className="dd" />
              </div>
              <div className="button-wrap monthly hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="silver-monthly"
                  data-item-name="Silver Member: Monthly"
                  data-item-price="99"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Silver Member: Monthly"
                  data-item-selected-plan="silver-monthly"
                  data-plan1-id="silver-monthly"
                  data-plan1-name="Silver Member: Monthly"
                  data-plan1-frequency="Monthly"
                  data-plan1-interval="1"
                  data-item-plan1-price="99"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
              <div className="button-wrap annual hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="silver-annually"
                  data-item-name="Silver Member: Annually"
                  data-item-price="1128"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Silver Member: Annually"
                  data-item-selected-plan="silver-annually"
                  data-plan1-id="silver-annually"
                  data-plan1-name="Silver Member: Annually"
                  data-plan1-frequency="Yearly"
                  data-plan1-interval="1"
                  data-item-plan1-price="1128"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
            </div>
            <div className="cell package gold">
              <div className="name">
                <div className="package-title heading--m">
                  Gold
                  <br /> Member
                </div>
              </div>
              <div className="price">
                <div className="price-per-month">
                  £199 <span>/ month</span>
                </div>
                <div className="divider"></div>
                <div className="price-per-annum">
                  £2,388 <span>/ year</span>
                </div>
              </div>
              <div className="mob-info">
                <span className="label">Whats included: </span>
                <ul>
                  <li>
                    <span>Website:</span> Premium Online Profile (includes
                    extended business description, additional photos and
                    clickable links to website and socials) & 2 x Inclusion in
                    Blog Content & Dedicated Blogpost & Story Advert (12 months)
                  </li>
                  <li>
                    <span>Little Black Book:</span> Little Black Book Business
                    Profile & Full Page Magazine Ad
                  </li>
                  <li>
                    <span>Social Media:</span> 2 x Inclusion in a Social Post &
                    Dedicated Social Post & Competition
                  </li>
                  {/* <li>
                    <span>Email Marketing:</span> 2 x Inclusion in a newsletter
                  </li> */}
                  <li>
                    <span>Other:</span> Window Sticker
                  </li>
                </ul>
              </div>
              <div className="button_selector">
                <select
                  onChange={handleChange}
                  name="gold-select"
                  id="gold-select"
                >
                  <option selected disabled>
                    Pick a plan
                  </option>
                  <option>Pay monthly</option>
                  <option>Pay annually</option>
                </select>
                <DD className="dd" />
              </div>
              <div className="button-wrap monthly hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="gold-monthly"
                  data-item-name="Gold Member: Monthly"
                  data-item-price="199"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Gold Member: Monthly"
                  data-item-selected-plan="gold-monthly"
                  data-plan1-id="gold-monthly"
                  data-plan1-name="Gold Member: Monthly"
                  data-plan1-frequency="Monthly"
                  data-plan1-interval="1"
                  data-item-plan1-price="199"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
              <div className="button-wrap annual hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="gold-annually"
                  data-item-name="Gold Member: Annually"
                  data-item-price="2388"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Gold Member: Annually"
                  data-item-selected-plan="gold-annually"
                  data-plan1-id="gold-annually"
                  data-plan1-name="Gold Member: Annually"
                  data-plan1-frequency="Yearly"
                  data-plan1-interval="1"
                  data-item-plan1-price="2388"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
            </div>

            <div className="cell package platinum">
              <div className="name">
                <div className="package-title heading--m">
                  Platinum
                  <br /> Member
                </div>
              </div>
              <div className="price">
                <div className="price-per-month">
                  £349 <span>/ month</span>
                </div>
                <div className="divider"></div>
                <div className="price-per-annum">
                  £4,188 <span>/ year</span>
                </div>
              </div>
              <div className="mob-info">
                <span className="label">Whats included: </span>
                <ul>
                  <li>
                    <span>Website:</span> Premium Online Profile (includes
                    extended business description, additional photos and
                    clickable links to website and socials) & 2 x Inclusion in
                    Blog Content & Dedicated Blogpost & Homepage Banner Ad (6
                    months) & Story Advert (6 months)
                  </li>
                  <li>
                    <span>Little Black Book:</span> Little Black Book Business
                    Profile & 20% off Little Black Book Ads & 4 x Full Page
                    Magazine Ad
                  </li>
                  <li>
                    <span>Social Media:</span> Dedicated Instagram Story & 2 x
                    Inclusion in a Social Post & 2 x Dedicated Social Post
                  </li>
                  <li>
                    <span>Email Marketing:</span> 2 x Inclusion in a newsletter
                    & Dedicated email
                  </li>
                  <li>
                    <span>Other:</span> Window Sticker
                  </li>
                </ul>
              </div>
              <div className="button_selector">
                <select
                  onChange={handleChange}
                  name="platinum-select"
                  id="platinum-select"
                >
                  <option selected disabled>
                    Pick a plan
                  </option>
                  <option>Pay monthly</option>
                  <option>Pay annually</option>
                </select>
                <DD className="dd" />
              </div>
              <div className="button-wrap monthly hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="platinum-monthly"
                  data-item-name="Platinum Member: Monthly"
                  data-item-price="349"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Platinum Member: Monthly"
                  data-item-selected-plan="platinum-monthly"
                  data-plan1-id="platinum-monthly"
                  data-plan1-name="Platinum Member: Monthly"
                  data-plan1-frequency="Monthly"
                  data-plan1-interval="1"
                  data-item-plan1-price="349"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
              <div className="button-wrap annual hide">
                <button
                  className="snipcart-add-item"
                  data-item-id="platinum-annually"
                  data-item-name="Platinum Member: Annually"
                  data-item-price="4188"
                  data-item-url={`${process.env.GATSBY_SNIPCART_AUTH_URL}`}
                  data-item-description="Platinum Member: Annually"
                  data-item-selected-plan="platinum-annually"
                  data-plan1-id="platinum-annually"
                  data-plan1-name="Platinum Member: Annually"
                  data-plan1-frequency="Yearly"
                  data-plan1-interval="1"
                  data-item-plan1-price="4188"
                  data-item-shippable={false}
                >
                  Sign up now
                </button>
              </div>
            </div>

            {/* <div className="cell package bespoke">
              <div className="name">
                <div className="package-title heading--m">Bespoke</div>
              </div>
              <div className="price">
                <div className="price-per-annum">£TBC</div>
              </div>
              <div className="button-wrap">
                <FakeButtonModal
                  color="white"
                  text="Enquire now"
                  ignoreStyles={true}
                  withHover={true}
                  className="fake_button"
                />
              </div>
            </div> */}
            <div className="cell package pick-n-mix mob-only">
              <div className="name">
                <div className="package-title heading--m">Pick & Mix</div>
              </div>
              <div className="price">
                <div className="price-per-annum">£POA</div>
              </div>
              <div className="mob-info">
                <span className="label">Whats available: </span>
                <ul>
                  <li>
                    <span>Magazine:</span> Outside Back Cover, Inside Front or
                    Back Cover, Full Page Internal, Half Page Internal{" "}
                  </li>
                  <li>
                    <span>Website:</span> Blog Post (800 words / 6 images),
                    Promoted Story (homepage), Homepage Banner Advert, Featured
                    Independent (homepage), Story Page Advert (vertical), Story
                    Page Advert (small), Independent Directory Listing
                  </li>
                  <li>
                    <span>Email Marketing:</span> Dedicated E-mail, Inclusion in
                    Body of E-mail
                  </li>
                  <li>
                    <span>Little Black Book:</span> Full Page Business Listing,
                    30 pages of display adverts, 1/2 Page Advert, Full Page
                    Advert
                  </li>
                  <li>
                    <span>Social Media:</span> Organic Post (Facebook &
                    Twitter), Organic Post (Instagram), Instagram Story,
                    Targeted Advert (Facebook/Instagram), Competition (Organic &
                    Paid support)
                  </li>
                  <li>
                    <span>Other:</span> Video
                  </li>
                </ul>
              </div>
              <div className="button-wrap">
                <FakeButtonModal
                  color="white"
                  text="Enquire now"
                  ignoreStyles={true}
                  withHover={true}
                  className="fake_button"
                />
              </div>
            </div>
          </div>

          <div className="div-row">
            All prices are + vat. All our marketing packages are contracted to
            run for a 12 month period.
          </div>
          <div className="div-row">
            <strong>
              Non-independents, please get in touch if you'd like to work with
              us.{" "}
            </strong>
          </div>

          <PromotionsTableRow
            feature="Little Black Book"
            link={data.link}
            linkText={data.text}
            bronze="<ul>
                        <li><span>20% discount</span> on Little Black Book Business Profile</li>
                    </ul>"
            silver="<ul>
                                    <li><span>Little Black Book Business Profile</span></li>
                                </ul>"
            gold="<ul>
                                    <li><span>Little Black Book Business Profile</span></li>
                                </ul>"
            platinum="<ul>
                                    <li><span>Little Black Book Business Profile</span></li>
                                    <li><span>20% off Little Black Book Ads</span></li>
                                </ul>"
            become_a_member="<ul>
                                    <li><span>20% discount</span> on Little Black Book Business Profile</li>
                                </ul>"
          />

          <PromotionsTableRow
            feature="Magazine"
            bronze="XXXXX"
            silver="XXXXX"
            gold="<ul>
                                <li><span>1 x Full Page Magazine Ads</span></li>
                              </ul>"
            platinum="<ul>
                                <li><span>4 x Full Page Magazine Ads</span> (one per quarterly edition)</li>
                              </ul>"
            become_a_member="XXXXX"
          />

          <PromotionsTableRow
            feature="Website"
            bronze="<ul>
                                    <li><span>Premium Online Profile</span> includes extended business description, additional photos and clickable links to website and socials</li>
                                    <li><span>Inclusion in Blog Content</span></li>
                                </ul>"
            silver="<ul>
                                    <li><span>Premium Online Profile</span> includes extended business description, additional photos and clickable links to website and socials</li>
                                    <li><span>Inclusion in Blog Content</span></li>
                                    <li><span>Dedicated Blog post</span></li>
                                    <li><span>Story Advert</span> (3 months)</li>
                                </ul>"
            gold="<ul>
                                <li><span>Premium Online Profile</span> includes extended business description, additional photos and clickable links to website and socials</li>
                                <li><span>2 x Inclusion in Blog Content</span></li>
                                <li><span>Dedicated Blog post</span></li>
                                <li><span>Story Advert</span> (12 months)</li>
                            </ul>"
            platinum="<ul>
                                <li><span>Premium Online Profile</span> includes extended business description, additional photos and clickable links to website and socials</li>
                                <li><span>2 x Inclusion in Blog Content</span></li>
                                <li><span>Dedicated Blog post</span></li>
                                <li><span>Homepage Banner Ad</span> (3 months)</li>
                                <li><span>Story Advert</span> (3 months)</li>
                            </ul>"
            become_a_member="<ul>
                                    <li><span>Online Profile</span> with google maps link and offers function</li>
                                </ul>"
          />

          <PromotionsTableRow
            feature="Social Media"
            bronze="<ul>
                                    <li><span>Dedicated Instagram Story</span></li>
                                    <li><span>2 x Inclusion in a Social Post</span></li>
                                </ul>"
            silver="<ul>
                                    <li><span>Dedicated Instagram Story</span></li>
                                    <li><span>2 x Inclusion in a Social Post</span></li>
                                    <li><span>Dedicated Social Post</span></li>
                                </ul>"
            gold="<ul>
                                <li><span>Dedicated Instagram Story</span></li>
                                <li><span>2 x Inclusion in a Social Post</span></li>
                                <li><span>Dedicated Social Post</span></li>
                                <li><span>Competition</span></li>
                            </ul>"
            platinum="<ul>
                                <li><span>Dedicated Instagram Story</span></li>
                                <li><span>2 x Inclusion in a Social Post</span></li>
                                <li><span>2 x Dedicated Social Post</span></li>
                            </ul>"
            become_a_member="<ul>
                                    <li><span>Dedicated Instagram Story</span></li>
                                    <li><span>Inclusion in a Social Post</span></li>
                                </ul>"
          />

          <PromotionsTableRow
            feature="Email Marketing"
            bronze="<ul>
                                    <li><span>X</span></li>
                                </ul>"
            silver="<ul>
                                    <li><span>X</span></li>
                                </ul>"
            gold="<ul>
                                <li><span>X</span></li>
                            </ul>"
            platinum="<ul>
                                <li><span>2 x Inclusion in a newsletter</span></li>
                                <li><span>Dedicated Email</span></li>
                            </ul>"
            become_a_member="XXXXX"
          />

          <PromotionsTableRow
            feature="Other"
            bronze="<ul>
                                    <li><span>Window Sticker</span></li>
                                </ul>"
            silver="<ul>
                                    <li><span>Window Sticker</span></li>
                                </ul>"
            gold="<ul>
                                <li><span>Window Sticker</span></li>
                            </ul>"
            platinum="<ul>
                                <li><span>Window Sticker</span></li>
                            </ul>"
            become_a_member="<ul>
                                            <li><span>Window Sticker</span></li>
                                        </ul>"
          />

          <button
            className="picknmix-row row"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          >
            <div className="cell">
              <div className="heading--m">Pick & Mix</div>
              <div className="poa">£POA</div>
            </div>
            <div className="text">
              Choose your individual promotion options below and contact us for
              pricing information.
            </div>
            <div className="cell icon">
              <IoChevronDownSharp />
            </div>
          </button>
        </div>
        <PromotionsPickMixRow toggle={pickMixToggle} />
      </div>
    </PromotionsPackagesComp>
  )
}
