import React from 'react'
import styled from 'styled-components';
import Icon1 from '../../assets/benefit1.inline.svg';
import Icon2 from '../../assets/benefit2.inline.svg';
import Icon3 from '../../assets/benefit3.inline.svg';
import Icon4 from '../../assets/benefit4.inline.svg';

const BenefitsWrapper = styled.section`
background:var(--grey);
padding:4rem 0 0 0;

    @media only screen and (min-width: 1024px) {
    padding:8rem 0 4rem 0px;    
    }

    .heading--l{
    margin-bottom:4rem;
    }

    ul{
    list-style:none;
    display:flex;
    flex-wrap:wrap;
    width:100%;
    justify-content:space-between;

        li{
        display:flex;
        flex-wrap:wrap;    
        width:100%;
        margin-bottom:2rem;

            @media only screen and (min-width: 750px) {
            width:calc(50% - 2rem);
            }
            
            @media only screen and (min-width: 1024px) {
            width:20%;
            margin-bottom:0;
            }



            &:last-of-type{
            margin-right:0;
            }

            .top{
            width:100%;
            display:flex;
            align-items:center;
            margin-bottom:1rem;

                .icon{
                margin-right:1rem;
                }

                .heading{
                font-size:1.4rem;
                font-family:var(--font-bold);
                }    
            }

            .text--inner{
            line-height:1.5rem;
            font-size:0.9rem;
            }

        }

    }

`;

export default function Benefits() {
    return (
        <BenefitsWrapper>
            <div className="wrapper">
                <div className="heading--l">What are the benefits?</div>

                <ul>
                    <li>
                        <div className="top">
                            <Icon1 className="icon"/>
                            <div className="heading">See real results</div>
                        </div>
                        <div className="text">
                            <div className="text--inner">Reach over 650,000 active 'treat seekers' with our award-winning content and unique writing style.</div>
                        </div>
                    </li>
                    <li>
                        <div className="top">
                            <Icon2 className="icon"/>
                            <div className="heading">Strengthen brand loyalty</div>
                        </div>
                        <div className="text">
                            <div className="text--inner">Accelerate your message to the top of search engines to get potential customers to discover your brand first.</div>
                        </div>
                    </li>
                    <li>
                        <div className="top">
                            <Icon3 className="icon"/>
                            <div className="heading">Drive brand awareness</div>
                        </div>
                        <div className="text">
                            <div className="text--inner">Share your stories, passions and values in your words across print and digital, including our widely followed social media platforms.</div>
                        </div>
                    </li>
                    <li>
                        <div className="top">
                            <Icon4 className="icon"/>
                            <div className="heading">Maximise your customer base</div>
                        </div>
                        <div className="text">
                            <div className="text--inner">Enjoy a return on your investment and receive analytical reports showing exactly what your money has achieved. </div>
                        </div>
                    </li>
                </ul>
            </div>
        </BenefitsWrapper>
    )
}
