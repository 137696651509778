import React, { useEffect, useState, useRef } from "react"
import HeroDefault from "../components/global/HeroDefault"
import AboutDemographics from "../components/advertise/AboutDemographics"
import HowWeDoIt from "../components/advertise/HowWeDoIt"
import PromotionsPackages from "../components/advertise/PromotionsPackages"
import TestimonialsTest from "../components/advertise/Testimonials"
import CaseStudySlider from "../components/global/CaseStudySlider"
import Benefits from "../components/advertise/Benefits"
import FormCTA from "../components/global/FormCTA"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"

const AdvertiseWrap = styled.div`
  .testimonials-wrapper {
    padding: 4rem 0 0 0;

    @media only screen and (min-width: 1024px) {
      padding: 8rem 0 0 0;
    }

    &:before {
      width: 100%;
      height: 50%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: var(--teal);
    }
  }
`

export default function Home({ data }) {
  const seo = data.seo.seo

  return (
    <AdvertiseWrap>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        image={data.og.options.acf_options.ogAdvertise.mediaItemUrl}
      />
      <HeroDefault
        title={data.advertise.title}
        text="<p>Are you ready to be discovered by 650,000 highly receptive readers? Whether you’re an independent business or a business with an independent state of mind, we can help you access hundreds of thousands of ABC1 ‘treat seekers’ who want to know about the best experiences on offer in and around Leeds.</p> "
        buttonText="Find out more"
        backgroundImage={data.advertise.acf_advertise_with_us.heroImage}
        buttonType={"external"}
        buttonLink={"https://independentlifeuk.co.uk"}
        backgroundColor={"brandColor"}
      />
      <AboutDemographics stats={data.stats} />
      <HowWeDoIt image={data.advertise.acf_advertise_with_us.howWeDoItImage} />
      {/* <PromotionsPackages
        link={data.advertise.acf_advertise_with_us.littleBlackBookFormUrl}
        text={data.advertise.acf_advertise_with_us.littleBlackBookButtonText}
      /> */}
      <TestimonialsTest
        testimonials={data.advertise.acf_advertise_with_us.testimonials}
      />
      <CaseStudySlider
        sectionTitle="Case Studies"
        linkPrefix={""}
        data={data.advertise.acf_advertise_with_us.caseStudies}
        bgPosition="bottom"
        bgSize="50%"
        bgColor="grey"
      />
      <Benefits />
      <FormCTA
        ctaHeading={
          data.advertise.acf_advertise_with_us.formCallToAction.ctaHeading
        }
        ctaText={data.advertise.acf_advertise_with_us.formCallToAction.ctaText}
        formName={"Advertise"}
        formHeading={
          data.advertise.acf_advertise_with_us.formCallToAction.formHeading
        }
        formBuilder={
          data.advertise.acf_advertise_with_us.formCallToAction.formBuilder
        }
        sectionHeading={
          data.advertise.acf_advertise_with_us.formCallToAction.sectionHeading
        }
        bgSize="50%"
        bgColor="grey"
        marginTop={false}
        bgPosition="top"
      />
    </AdvertiseWrap>
  )
}

export const query = graphql`
  {
    stats: wp {
      options {
        acf_options {
          stats {
            longLabel
            stat
            statSuffix
            icon
          }
        }
      }
    }

    og: wp {
      options {
        acf_options {
          ogAdvertise {
            mediaItemUrl
          }
        }
      }
    }

    seo: wpPage(title: { eq: "Advertise With Us" }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }
    advertise: wpPage(title: { eq: "Advertise With Us" }) {
      id
      title
      acf_advertise_with_us {
        littleBlackBookButtonText
        littleBlackBookFormUrl
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        formCallToAction {
          ctaHeading
          ctaText
          sectionHeading
          formHeading
          formBuilder {
            label
            option
            typeFieldType
          }
        }

        caseStudies {
          chooseACaseStudy {
            ... on WpCase_study {
              slug
              acf_caseStudyFields {
                logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                backgroundImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }

        howWeDoItImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        testimonials {
          testimony
          name
          company
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
