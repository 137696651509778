import React, {useRef} from 'react'
import styled from 'styled-components';

const PickMixDetail = styled.div`
/* border-left:1px solid #676767; */
border-right:1px solid #676767;
width:calc(100% + 4rem);
margin-left:-2rem;
/* padding:2rem 2rem 0 2rem; */
background:#92A3A3;
position: relative;
overflow:hidden;
transition:0.5s max-height ease-in-out;
max-height:${props => props.maxHeight+'px'};


    .pickMixInner{
    transition:0.5s all ease-in-out;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    }

    .block{
    width:calc(100% / 3);
    padding:2rem;    
    text-align:center;
    border-bottom:1px solid #676767;
    border-left:1px solid #676767;

        .heading--s{
        margin-bottom:1rem;
        }

        .block-row{
        width:100%;
        display:flex;
        justify-content:space-between;
        font-size:0.9rem;
        justify-items:flex-start;
        
            .item{
                line-height:1.5rem;
                text-align:center;
                width:100%;
            }

        }
    }   
        

`

export default function PromotionsPickMixRow(data) {
    const PickMixContainer = useRef(null);

    
    return (
    
        <PickMixDetail 
        className="picknmix-detail row" 
        maxHeight={data.toggle === true ? PickMixContainer.current.offsetHeight : '0'}
        >
            
            <div className="pickMixInner" ref={PickMixContainer}>
                <div className="block">
                    <div className="heading--s">IL Magazine</div>
                    <div className="block-row">
                        <div className="item">Outside Back Cover</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Inside Front or Back Cover</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Full Page Internal Advert</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Half Page Internal Advert</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Sponsored Content</div>
                    </div>
                    <div className="block-row">
                        <div className="item">4 x Full Page Internal Adverts (1 year)</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Design of Display Ad (if required, includes one edit)</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Magazine Bellyband</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Magazine Flyer Insert</div>
                    </div>
                </div>
                <div className="block">
                    <div className="heading--s">Website</div>
                    <div className="block-row">
                        <div className="item">Blog Post (800 words / 6 images)</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Promoted Story (homepage)</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Inclusion in a Blogpost</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Homepage Banner Advert</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Featured Independent (homepage)</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Story Page Advert</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Independent Directory Listing</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Competition including social sharing</div>
                    </div>
                </div>
                <div className="block">
                    <div className="heading--s">E-mail Marketing</div>
                    <div className="block-row">
                        <div className="item">Dedicated E-mail</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Inclusion in Body of E-mail</div>
                    </div>
                </div>
                <div className="block">
                    <div className="heading--s">Little Black Book</div>
                    <div className="block-row">
                        <div className="item">Full Page Business Listing</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Full Page Display Advert</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Inside Front / Inside Back Advert</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Sponsored Content</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Sponsorship (minimum 5,000)</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Design of Display Ad (if required, includes one edit)</div>
                    </div>
                </div>
                <div className="block">
                    <div className="heading--s">Social Media</div>
                    <div className="block-row">
                        <div className="item">Facebook Post & Instagram Story</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Instagram Grid Post</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Instagram Carousel: Business Intro</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Instagram Story</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Instagram Story: Casual Film</div>
                    </div>
                    <div className="block-row">
                        <div className="item">Instagram Guide</div>
                    </div>
                </div>
                <div className="block">
                    <div className="heading--s">Other Services</div>
                    <div className="block-row">
                        <div className="item">Professional Video (1 minute)</div>
                    </div>
                </div>
            </div>
        </PickMixDetail>

    )
}
