import React from "react"
import parse from "html-react-parser"
import styled from "styled-components"
import { IoCloseSharp } from "react-icons/io5"
import { Link } from "gatsby"

const RowDetail = styled.div`
  width: 100%;
  position: relative;

  &:before {
    width: calc(100% + 4rem);
    left: -2rem;
    bottom: 0;
    position: absolute;
    content: "";
    height: 1px;
    background: #676767;
  }

  .cell {
    padding: 4rem 0;
    /* padding-top:4rem; */
  }

  .feature {
    color: var(--black);
    font-family: var(--font-bold);
  }

  .hasLink {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;

    .link {
      width: 100%;
      position: relative;
      margin-top: 1rem;
      font-size: 0.8rem;
      color: var(--white);
      /* color:var(--brandColor); */
    }
  }

  .bronze,
  .silver,
  .gold,
  .platinum,
  .become-a-member {
    font-size: 0.8rem;
    text-align: center;

    &.icon {
      /* width:100%; */
    }

    svg {
      margin: 0 auto;
      position: relative;
      width: 20px;
      height: 20px;
    }

    ul {
      list-style: none;

      li {
        line-height: 1.2rem;
        margin-bottom: 1.3rem;
        position: relative;
        padding: 0 1.5rem 0 0.75rem;

        &:last-child {
          margin-bottom: 0;
        }

        /* &:before{
                width:5px;
                height:5px;
                position: absolute;
                top:5px;
                left:0;
                border-radius:5px;
                background:var(--white);
                content:"";
                } */

        span {
          font-family: var(--font-bold);
          width: 100%;
          display: block;
          line-height: 1.2rem;
        }
      }
    }
  }
`

export default function PromotionsTableRow(data) {
  // console.log(data)
  return (
    <RowDetail className="row row-detail">
      <div className={`cell feature ${data.link ? "hasLink" : ""}`}>
        {data.feature}
        {data.link ? (
          <Link className="link" to={"/" + data.link}>
            {data.linkText}
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="cell become-a-member">
        {data.become_a_member === "XXXXX" ? (
          <IoCloseSharp />
        ) : (
          parse(data.become_a_member)
        )}
      </div>
      <div
        className={data.bronze === "XXXXX" ? "cell bronze icon" : "cell bronze"}
      >
        {data.bronze === "XXXXX" ? <IoCloseSharp /> : parse(data.bronze)}
      </div>
      <div
        className={data.bronze === "XXXXX" ? "cell silver icon" : "cell silver"}
      >
        {data.silver === "XXXXX" ? <IoCloseSharp /> : parse(data.silver)}
      </div>
      <div className="cell gold">{parse(data.gold)}</div>
      <div className="cell platinum">{parse(data.platinum)}</div>
    </RowDetail>
  )
}
