import React, {useState} from 'react'
import styled from 'styled-components';
import FakeButtonModal from '../global/FakeButtonModal';
import ButtonExternal from '../global/ButtonExternal';
import AnimatedImage from '../global/AnimatedImage'
import { useInViewEffect } from 'react-hook-inview'

const HWDI = styled.section`
position:relative;
padding-bottom:4rem;

    &:after{
    width:100%;
    height:calc(100% - 92px);
    background:var(--teal);
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    z-index:-1;
    }

    .left{
    width:100%;
    margin-bottom:4rem;
    
        @media only screen and (min-width: 1024px) {
            width:calc(50% - 4rem);
            margin-right:4rem;
            margin-bottom:0;
        }

        .heading--l{
        margin-bottom:6rem;
        }
        .text{
        color:var(--white);
        line-height:1.5rem;
        font-size:0.9rem;
        margin-bottom:4rem;
        }

        .fakeButton{
        margin-bottom:2rem;
    }
    
        .btn_outerwrapper{        
        display:inline-flex;
        flex-wrap:wrap;

            .btn_wrapper{
            margin-right:1rem;
            }

        }
    }
    .right{
    width:calc(100% + 2rem);
    height:auto;
    position: relative;


        @media only screen and (min-width: 1024px) {
            width:50%;
            height:100%;
            position:absolute;
            top:0;
            right:0;
        }
    
        .image-wrapper{
        width: 90%;

            .gatsby-image-wrapper{
            overflow:visible !important;             
            }

            &.active{
                .gatsby-image-wrapper{
                    &:before{
                    transform:translate(0,0);
                    }
                }
            }

        }
    }
`

export default function HowWeDoIt(data) {
    const [isVisible, setIsVisible] = useState(false);
    

    const ref = useInViewEffect(
        ([entry], observer) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active')
          }
          setIsVisible(entry.isIntersecting)
        },
        { threshold: 0.75 },
      )
    return (
        <HWDI>
            <div className="wrapper">
                <div className="left">
                    <h3 className="heading--l">How We Do It</h3>  
                    <div className="text">We understand online. We understand digital. We understand print. And most importantly, we understand what it takes to create a compelling independent city marketing platform.
Our high-quality print publications and extensive online presence champion independent business from the city to the suburbs. We facilitate real, tangible results, and continually evaluate your content – single pieces, series and the entire strategy – to ensure it is relevant, timely and attracts the engagement it deserves. 
Your potential customers are just a click or a page turn away.</div>
                    <div className="btn_outerwrapper">
                        <div className="btn_wrapper">
                            <FakeButtonModal
                            color="white"
                            text="Enquire now"
                            withHover={true}
                            />
                        </div>
                        <div className="btn_wrapper">
                            <ButtonExternal
                            color="white"
                            bg={'white'}
                            text={'View our publications'}
                            link={'https://issuu.com/independentlife'}
                            />
                        </div>
                    </div>
                </div>  
                <div className="right">
                    <AnimatedImage 
                        imgSrc={data.image.localFile.childImageSharp.gatsbyImageData}
                        offsetX={'3rem'}
                        offsetY={'3rem'}
                        color={'brandColor'}
                    />
                </div>         
            </div>   

        </HWDI>
    )
}
